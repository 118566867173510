.UIComFlex {
  width: 100%;
  z-index: -1;
  margin-top: -140px;
  overflow: hidden;
}

.UIComFlex .container-fluid.background {
  padding: 15vw 0 6vw;
  position: relative;
}

.UIComFlex .container-fluid.background .bg-image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.UIComFlex video.header-video {
  width: 100%;
  height: auto;
  position: absolute;
}

.UIComFlex .container.hero-image {
  margin-top: -100px;
  z-index: 9;
  position: relative;
}
.UIComFlex .container.hero-image img {
  width: inherit;
}

.UIComFlex .container.content {
  margin: 100px auto;
  padding: 0 10vw;
}

.UIComFlex .container.video {
  margin: 0 auto 5vw;
  text-align: center;
}
.UIComFlex .container.video video#video {
  border-radius: 20px;
}

.UIComFlex .overlay {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: -1;
  margin: -910px 0 -800px;
}

@media (max-width: 575.98px) {
  .UIComFlex .container-fluid.background {
    padding: 30vw 0 28vw;
    position: relative;
  }
  .UIComFlex .overlay {
    margin: -200px 0 -1150px;
    height: 1350px;
  }
  .UIComFlex .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UIComFlex .container-fluid.background {
    padding: 25vw 0 22vw;
    position: relative;
  }
  .UIComFlex .overlay {
    margin: -350px 0 -950px;
    height: 1300px;
  }
  .UIComFlex .container-fluid.background .bg-image {
    left: -30%;
    right: -30%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UIComFlex .container-fluid.background {
    padding: 20vw 0 16vw;
    position: relative;
  }
  .UIComFlex .overlay {
    margin: -470px 0 -830px;
    height: 1300px;
  }
  .UIComFlex .container-fluid.background .bg-image {
    left: -30%;
    right: -30%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .UIComFlex .container-fluid.background {
    padding: 16vw 0 10vw;
    position: relative;
  }
  .UIComFlex .overlay {
    margin: -600px 0 -750px;
    height: 1300px;
  }
  .UIComFlex .container-fluid.background .bg-image {
    left: -20%;
    right: -20%;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .UIComFlex .container-fluid.background {
    padding: 16vw 0 10vw;
    position: relative;
  }
  .UIComFlex .overlay {
    margin: -650px 0 -700px;
    height: 1300px;
  }
  .UIComFlex .container-fluid.background .bg-image {
    left: -20%;
    right: -20%;
  }
}
@media (min-width: 1400px) and (max-width: 2000px) {
  .UIComFlex .container-fluid.background {
    padding: 16vw 0 10vw;
    position: relative;
  }
  .UIComFlex .overlay {
    margin: -890px 0 -700px;
    height: 1540px;
  }
  .UIComFlex .container-fluid.background .bg-image {
    left: -15%;
    right: -15%;
  }
}