@import url("https://use.typekit.net/cms8vgg.css");

* {
    scroll-behavior: smooth;
    font-family: 'Roboto',
    sans-serif;
}
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #222731;
}

::-webkit-scrollbar-thumb {
    background: #5eba47;
}

/* Firefox scrollbar */
@-moz-document url-prefix() {
    * {
        scrollbar-width: thin;
        scrollbar-color: #5eba47 #222731;
    }
}


html {
    scroll-behavior: smooth;
    overflow: overlay;
}


body.disable_scroll {
    overflow: hidden;
    height: 100vh;
}

.btn-primary {
    border-radius: 10px;
    color: #fff;
    background-color: #373f4d;
    padding: 15px 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    box-shadow: 2px 2px 5px -2px #383838;
}

.btn-primary:hover {
    border-radius: 10px;
    color: #fff;
    background-color: #67bc4f;
    padding: 15px 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    box-shadow: 2px 2px 5px -2px #383838;
}

.btn-secondary {
    border-radius: 10px;
    color: #fff;
    background-color: #67bc4f;
    padding: 15px 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    box-shadow: 2px 2px 5px -2px #383838;
}

.btn-secondary:hover {
    border-radius: 10px;
    color: #fff;
    background-color: #373f4d;
    padding: 15px 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    box-shadow: 2px 2px 5px -2px #383838;
}

p {
    margin-top: 0;
    margin-bottom: 2rem;
}

.content .text {
    margin: 0 auto;
    text-align: justify;

    p {
        color: #373f4d;
        text-align: justify;
    }
    h4 {
        font-size: 36px;
        text-align: left;
        color: #373f4d;
        margin: 50px 0 20px;
    }
}

.heading h1 {
    font-size: 85px;
    font-weight: 700;
    color: #fff;
}

.heading h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #5eba47;
}

.heading h3 {
    font-size: 55px;
    color: #373f4d;
    font-weight: 700;
}

.heading h4 {
    font-size: 16px;
    color: #5eba47;
    text-transform: uppercase;
    font-weight: 700;
}

.heading {
    text-align: center;
    // width: 60%;
    margin: 0 auto;
}

.cards {
    margin: 100px auto;

    .heading h3 {
        font-size: 55px;
        color: #373f4d;
        font-weight: 700;
    }

    .heading h4 {
        font-size: 16px;
        color: #5eba47;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.row.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .card {
        border: none;
        border-radius: 20px;
        background-color: #222731;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#373f4d));
        background-image: linear-gradient(#222731, #373f4d);
        img {
            width: 100%;
            height: auto;
        }
    }

    .card:hover {
        border: none;
        border-radius: 20px;
        background-color: #222731;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#204017), to(#5eba47));
        background-image: linear-gradient(#204017, #5eba47);
    }

    .readmore {
        position: absolute;
        bottom: 30px;
        right: 20px;

        a {
            font-size: 14px;
            color: white;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            border-bottom: 3px solid #5eba47;
            padding-bottom: 3px;
        }
    }

    .card-link:hover {
        a {
            border-bottom: 3px solid #fff;
        }
    }

    .card-shadow {
        margin-top: 40px;
    }

    .card-text {
        position: absolute;
        padding: 50px 20px 0;

        inline-size: -webkit-fill-available;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        h3 {
            color: white;
            font-size: 48px;
        }

        h4 {
            color: #5eba47;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}

span.enaslov {
    color:#5eba47;
}
.show {
    display: block;
}

.hide {
    display: none;
}
.container-fluid.main {
    background-color: white;
}

video.header-video {
    background-color: #232833;
}

.footer a {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
}
.footer a:hover {
    color: #5eba47;
}


#faqAccordion {
    h4 button {
        color:#5eba47;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
    }
    .accordion-item, .accordion-button {
        background-color: #e9e9ea;
        border-radius: 8px;
        border: none;
    }
    .accordion-item {
        margin-bottom: 10px;
        padding: 20px;
    }

}

@media (max-width: 575.98px) {
    .btn-primary {
        padding: 10px 15px;
        font-size: 10px;
    }

    .btn-secondary {
        padding: 10px 15px;
        font-size: 10px;
    }
    .row.cards .card-text {
        inline-size: -webkit-fill-available;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        position: absolute;
        padding: 20px 20px 0;
    }
    .row.cards .card-text h3 {
        font-size: 35px;
    }

    .heading h2 {
        font-size: 15px;
    }

    .heading h1 {
        font-size: 45px;
    }

    .heading {
        width: 100%;
    }
    .container-fluid.background .bg-image {
        left: -50%;
        right: -50%;
    }
    .cards .card-shadow img {
        width: 100%;
    }
}

@media (min-width : 576px) and (max-width: 767.98px) {
    .row.cards .card-text {
        inline-size: -webkit-fill-available;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        position: absolute;
        padding: 40px 20px 0;
    }
    .row.cards .card-text h3 {
        font-size: 35px;
    }

    .heading h2 {
        font-size: 15px;
    }

    .heading h1 {
        font-size: 45px;
    }

    .heading {
        width: 100%;
    }
    .container-fluid.background .bg-image {
        left: -50%;
        right: -50%;
    }
    .cards .card-shadow img {
        width: 100%;
    }
}

@media (min-width : 768px) and (max-width: 991.98px) {
    .row.cards .card-text {
        inline-size: -webkit-fill-available;
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        position: absolute;
        padding: 20px 20px 0;
    }
    .row.cards .card-text h3 {
        font-size: 25px;
    }
    

    .heading h2 {
        font-size: 18px;
    }

    .heading h1 {
        font-size: 50px;
    }
    .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }
    .cards .card-shadow img {
        width: 100%;
    }
}

@media (min-width : 992px) and (max-width: 1199.98px) {
    .row.cards .card-text h3 {
        font-size: 30px;
    }

    .heading h2 {
        font-size: 22px;
    }

    .heading h1 {
        font-size: 65px;
    }
    .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }

    .cards .card-shadow img {
        width: 100%;
    }
}

@media (min-width : 1200px) and (max-width: 1399.98px) {
    .row.cards .card-text h3 {
        font-size: 40px;
    }

    .heading h2 {
        font-size: 25px;
    }

    .heading h1 {
        font-size: 75px;
    }
    .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }
    .cards .card-shadow img {
        width: 100%;
    }
}


#cc--main {
    z-index: 999999!important
}