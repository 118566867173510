.UIJobDescription {
  width: 100%;
  z-index: -3;
  margin-top: -140px;
  white-space: pre-line;
  vertical-align: bottom;
}

.UIJobDescription .container-fluid.background {
  background-color: #373f4d;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#fff));
  background-image: linear-gradient(#222731, #fff);
  padding: 200px 0 120px;
}

.UIJobDescription .job-title {
  font-size: 45px;
}

.UIJobDescription a {
  color: #5eba47;
  text-decoration: none;
}

.UIJobDescription .content .text {
  margin: 0 -5vw;
  text-align: justify;
}
.UIJobDescription .content .text h4 {
  font-size: 25px;
  text-align: left;
  color: #373f4d;
  margin: 30px 0 5px;
}
.UIJobDescription .content .text p {
  text-align: justify;
}

.UIJobDescription .heading {
  text-align: center;
}

.UIJobDescription .heading h2 {
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  color: #5eba47;
}

.UIJobDescription .heading h1 {
  font-size: 75px;
  font-weight: 700;
  color: #fff;
}

.UIJobDescription .container.hero-image {
  margin-top: -250px;
}
.UIJobDescription .container.hero-image img {
  width: inherit;
}

.UIJobDescription .container.content {
  margin: 50px auto 100px;
  padding: 0 10vw;
}

.UIJobDescription .container.video {
  margin: 0 auto 5vw;
  text-align: center;
}
.UIJobDescription .container.video video#video {
  border-radius: 20px;
  width: 100%;
}

.UIJobDescription form .row {
  margin: 0 0 20px;
}
.UIJobDescription form .row .form-element {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: stretch;
}

.UIJobDescription form .row .form-element label {
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  padding: 0 0 15px 0;
}

.UIJobDescription form .row .form-element input,
.UIJobDescription form .row .form-element textarea {
  background-color: transparent;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #888a90;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 0px 5px 0px #32363f;
}

.UIJobDescription form .row .form-element input:-internal-autofill-selected,
.UIJobDescription form .row .form-element textarea:-internal-autofill-selected {
  background: transparent;
}

.UIJobDescription form .row .form-element input:focus,
.UIJobDescription form .row .form-element textarea:focus {
  border: 1px solid #FFF;
  background-color: white;
  color: #222731;
}

.UIJobDescription input:focus-visible,
.UIJobDescription textarea:focus-visible {
  outline: none;
}

.UIJobDescription .text ul li a {
  text-decoration: none;
  color: #5eba47;
}

.UIJobDescription .text ul li a:hover {
  color: green;
}

.UIJobDescription .text ul li span {
  color: #888a90;
}

.UIJobDescription .text.documents p {
  margin: 20px 0 5px;
  font-weight: 600;
}

.UIJobDescription .text.documents p::after {
  display: contents;
  content: ":";
}

.UIJobDescription .heading {
  width: 100%;
}

@media (max-width: 575.98px) {
  .UIJobDescription .heading h1 {
    font-size: 50px;
  }
  .UIJobDescription .heading h2 {
    font-size: 20px;
  }
  .UIJobDescription .btn-primary {
    padding: 20px 25px;
    font-size: 20px;
  }
  .UIJobDescription form {
    margin-top: 80px;
  }
  .UIJobDescription form .row .form-element input, .UIJobDescription form .row .form-element textarea {
    width: 100%;
  }
  .UIJobDescription .container.video video#video {
    border-radius: 20px;
    width: 100%;
  }
  .UIJobDescription .container.video {
    margin: -180px auto 25vw;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UIJobDescription .btn-primary {
    padding: 20px 25px;
    font-size: 20px;
  }
  .UIJobDescription form {
    margin-top: 80px;
  }
  .UIJobDescription form .row .form-element input, .UIJobDescription form .row .form-element textarea {
    width: 100%;
  }
  .UIJobDescription .container.video video#video {
    border-radius: 20px;
    width: 100%;
  }
  .UIJobDescription .container.video {
    margin: -180px auto 25vw;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UIJobDescription .btn-primary {
    padding: 20px 25px;
    font-size: 20px;
  }
  .UIJobDescription form {
    margin-top: 80px;
  }
  .UIJobDescription form .row .form-element input, .UIJobDescription form .row .form-element textarea {
    width: 100%;
  }
  .UIJobDescription .container.video video#video {
    border-radius: 20px;
    width: 100%;
  }
  .UIJobDescription .container.video {
    margin: -180px auto 25vw;
  }
}