.UIComSolar {
    width: 100%;
    z-index: -1;
    margin-top: -140px;
    overflow: hidden;
}

.UIComSolar .container-fluid.background {
    padding: 15vw 0 6vw;
    position: relative;
}

.UIComSolar .container-fluid.background .bg-image {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.UIComSolar video.header-video {
    width: 100%;
    height: auto;
    position: absolute;
}

.UIComSolar .container.hero-image {
    margin-top: -100px;
    z-index: 9;
    position: relative;

    img {
        width: inherit;
    }
}

.UIComSolar .container.content {
    margin: 100px auto;
    padding: 0 10vw;
}

.UIComSolar .container.video {
    margin: 0 auto 5vw;
    text-align: center;

    video#video {
        border-radius: 20px;
    }
}

.UIComSolar .overlay {
    overflow: hidden;
    display: block;
    position: relative;
    z-index: -1;
    margin: -910px 0 -800px;
}


@media (max-width: 575.98px) {
    .UIComSolar .container-fluid.background {
        padding: 30vw 0 28vw;
        position: relative;
    }

    .UIComSolar .overlay {
        margin: -200px 0 -1150px;
        height: 1350px;
    }

    .UIComSolar .container-fluid.background .bg-image {
        left: -50%;
        right: -50%;
    }
}

@media (min-width : 576px) and (max-width: 767.98px) {
    .UIComSolar .container-fluid.background {
        padding: 25vw 0 22vw;
        position: relative;
    }

    .UIComSolar .overlay {
        margin: -350px 0 -950px;
    height: 1300px;
    }

    .UIComSolar .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }
}

@media (min-width : 768px) and (max-width: 991.98px) {
    .UIComSolar .container-fluid.background {
        padding: 20vw 0 16vw;
        position: relative;
    }

    .UIComSolar .overlay {
        margin: -470px 0 -830px;
    height: 1300px;
    }

    .UIComSolar .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }
}

@media (min-width : 992px) and (max-width: 1199.98px) {
    .UIComSolar .container-fluid.background {
        padding: 16vw 0 10vw;
        position: relative;
    }

    .UIComSolar .overlay {
        margin: -600px 0 -750px;
    height: 1300px;
    }

    .UIComSolar .container-fluid.background .bg-image {
        left: -20%;
        right: -20%;
    }
}

@media (min-width : 1200px) and (max-width: 1399.98px) {
    .UIComSolar .container-fluid.background {
        padding: 16vw 0 10vw;
        position: relative;
    }

    .UIComSolar .overlay {
        margin: -650px 0 -700px;
    height: 1300px;
    }

    .UIComSolar .container-fluid.background .bg-image {
        left: -20%;
        right: -20%;
    }
}
@media (min-width : 1400px) and (max-width: 2000px) {
    .UIComSolar .container-fluid.background {
        padding: 16vw 0 10vw;
        position: relative;
    }

    .UIComSolar .overlay {
        margin: -890px 0 -700px;
        height: 1540px;
    }

    .UIComSolar .container-fluid.background .bg-image {
        left: -15%;
    right: -15%;
    }
}