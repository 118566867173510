.UITerms {
    width: 100%;
    z-index: -3;
    margin-top: -140px;
}

.UITerms .container-fluid.background {
    background-color: #373f4d;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#fff));
    background-image: linear-gradient(#222731, #fff);
    padding: 250px 0 220px;
}

.UITerms .content .text {
    margin: 0 -5vw;
    text-align: justify;

    h4 {
        font-size: 36px;
        text-align: left;
        color: #373f4d;
        margin: 50px 0 20px;
    }

    p {
        text-align: justify;

    }
}
.UITerms .heading {
    text-align: inherit;
}

.UITerms .heading h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #5eba47;
}

.UITerms a {
    color: #5eba47;
    text-decoration: none;
}

.UITerms .heading h1 {
    font-size: 75px;
    font-weight: 700;
    color: #fff;
}

.UITerms li {
    margin-bottom: 15px;
}

.UITerms .container.hero-image {
    margin-top: -250px;

    img {
        width: inherit;
    }
}

.UITerms .container.content {
    margin: 50px auto 100px;
    padding: 0 10vw;
}

.UITerms .container.video {
    margin: 0 auto 5vw;
    text-align: center;

    video#video {
        border-radius: 20px;
        width: 100%;
    }
}

.UITerms form .row {
    margin: 0 0 20px;

    .form-element {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: stretch;
    }
}

.UITerms form .row .form-element label {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0 0 15px 0;
}

.UITerms form .row .form-element input,
.UITerms form .row .form-element textarea {
    background-color: transparent;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    border: 1px solid #888a90;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 5px 0px #32363f;
}

.UITerms form .row .form-element input:-internal-autofill-selected,
.UITerms form .row .form-element textarea:-internal-autofill-selected {
    background: transparent;
}

.UITerms form .row .form-element input:focus,
.UITerms form .row .form-element textarea:focus {
    border: 1px solid #FFF;
    background-color: white;
    color: #222731;
}

.UITerms input:focus-visible,
.UITerms textarea:focus-visible {
    outline: none;
}

.UITerms .text ul li a {
    text-decoration: none;
    color: #5eba47;
}
.UITerms .text ul li a:hover {
    color: green;
}
.UITerms .text ul li span {
    color: #888a90;
}

.UITerms .text.documents p {
    margin: 20px 0 5px;
    font-weight: 600;
}
.UITerms .text.documents p::after {
    display: contents;
    content: ":";
}

.UITerms .heading {
    width: 100%;
}


@media (max-width: 575.98px) { 
    .UITerms .heading h1 {
        font-size: 50px;
    }
    .UITerms .heading h2 {
        font-size: 20px;
    }

    .UITerms .btn-primary {
        padding: 20px 25px;
        font-size: 20px;
    }
    .UITerms form {
        margin-top: 80px;
    }
    .UITerms form .row .form-element input, .UITerms form .row .form-element textarea {
        width: 100%;
    }
    .UITerms .container.video video#video {
        border-radius: 20px;
        width: 100%;
    }
    .UITerms .container.video {
        margin: -180px auto 25vw;
    }
}
@media (min-width : 576px) and (max-width: 767.98px) { 
    .UITerms .btn-primary {
        padding: 20px 25px;
        font-size: 20px;
    }
    .UITerms form {
        margin-top: 80px;
    }
    .UITerms form .row .form-element input, .UITerms form .row .form-element textarea {
        width: 100%;
    }
    .UITerms .container.video video#video {
        border-radius: 20px;
        width: 100%;
    }
    .UITerms .container.video {
        margin: -180px auto 25vw;
    }

}
@media (min-width : 768px) and (max-width: 991.98px) { 
    .UITerms .btn-primary {
        padding: 20px 25px;
        font-size: 20px;
    }
    .UITerms form {
        margin-top: 80px;
    }
    .UITerms form .row .form-element input, .UITerms form .row .form-element textarea {
        width: 100%;
    }
    .UITerms .container.video video#video {
        border-radius: 20px;
        width: 100%;
    }
    .UITerms .container.video {
        margin: -180px auto 25vw;
    }
}
