.UIConnection {
    width: 100%;
    z-index: -1;
    margin-top: -140px;
    overflow: hidden;
}

.UIConnection .container.status {
    display: none;
}

.UIConnection .container-fluid.background {
    padding: 400px 0 400px;
    position: relative;
}

.UIConnection .container-fluid.background .bg-image {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.UIConnection video.header-video {
    width: 100%;
    height: auto;
    position: absolute;
}

.UIConnection .container.hero-image {
    margin-top: -450px;
    z-index: 9;
    position: relative;

    img {
        width: inherit;
    }
    &[class^="en_"] {
        margin-top: -230px !important;
    }
    &[class^="sl_"] {
        margin-top: -290px !important;
    }
}

.UIConnection .container.content {
    margin: 100px auto;
    padding: 0 10vw;
}

.UIConnection .container.video {
    margin: 0 auto 5vw;
    text-align: center;

    video#video {
        border-radius: 20px;
        width: 100%;
    }
}

.UIConnection .overlay {
    overflow: hidden;
    display: block;
    position: relative;
    z-index: -1;
    margin: -1120px 0 -850px;
}

.UIConnection form .row {
    margin: 0 0 20px;

    .form-element {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: stretch;
    }
}

.UIConnection form .row .form-element label {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0 0 15px 0;
}

.UIConnection form .row .form-element input,
.UIConnection form .row .form-element textarea {
    background-color: transparent;
    padding: 15px;
    font-size: 16px;
    color: #fff;
    border: 1px solid #888a90;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 5px 0px #32363f;
}

.UIConnection form .row .form-element input:-internal-autofill-selected,
.UIConnection form .row .form-element textarea:-internal-autofill-selected {
    background: transparent;
}

.UIConnection form .row .form-element input:focus,
.UIConnection form .row .form-element textarea:focus {
    border: 1px solid #FFF;
    background-color: white;
    color: #222731;
}

.UIConnection input:focus-visible,
.UIConnection textarea:focus-visible {
    outline: none;
}
.UIConnection svg {
    //filter: drop-shadow( 5px 8px 0px rgba(0, 0, 0, .7));
    background-color: transparent;
}
.UIConnection .idle {
    fill:#888a90;
    background-color: #888a90;
}
.UIConnection .increaseConsumption {
    fill:#5eba47;
    background-color: #5eba47;
}
.UIConnection .decreaseConsumption {
    fill: orange;
    background-color: orange;
}

.UIConnection #map {
    text-align: center;
}

.UIConnection .legend-item {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .legend-title  {
        margin: 0 15px;
    }
}

.UIConnection #increaseactivation .legend-color img {
    height: 70px;
    width: 70px;
    border-radius: 0;
    // background-color: #5eba47;
}
.UIConnection #idleactivation .legend-color img {
    height: 70px;
    width: 70px;
    border-radius: 0;
    // background-color: #888a90;  
}
.UIConnection #descreaseactivation .legend-color img {
    height: 70px;
    width: 70px;
    border-radius: 0;
    // background-color: orange;
}

.UIConnection path {
    transition: all .5s ease-in;
}
.UIConnection .container.status,
.UIConnection .container.legend {
    margin: 50px 0 100px 0;
}

.UIConnection #usage-form .form-element {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
}
.UIConnection #usage-form .form-element .form-help {
    font-size: 11px;
}
.UIConnection #usage-form .form-element input, .form-element select {
    background-color: transparent;
    padding: 10px;
    font-size: 14px;
    color: #222731;
    border: 1px solid #888a90;
    border-radius: 10px;
    width: 100%;
}

.row.center {
    justify-content: center;
}

.usage p {
    text-align: initial!important;
}
.usage .info.strong {
    font-weight: 700;
}

.usage .container {
    padding: 0 40px;
    h3 {
        margin: 0 -40px
    }
    h5 {
        margin: 0 -20px
    }
}


@media (max-width: 575.98px) {
    .UIConnection form .row .form-element input, .UIConnection form .row .form-element textarea {
        width: 100%;
    }
    .UIConnection .container-fluid.background {
        padding: 35vw 0 31vw;
        position: relative;
    }

    .UIConnection .overlay {
        margin-top: -1230px;
        height: 1980px;
    }

    .UIConnection .container-fluid.background .bg-image {
        left: -40%;
        right: -40%;
    }
    .UIConnection .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }
    .UIConnection .container.hero-image {
        margin-top: -140px;
    }
    .UIConnection .container .heading {
        padding-bottom: 160px;
    }
}

@media (min-width : 576px) and (max-width: 767.98px) {
    .UIConnection form .row .form-element input, .UIConnection form .row .form-element textarea {
        width: 100%;
    }
    .UIConnection .container-fluid.background {
        padding: 25vw 0 33vw;
        position: relative;
    }

    .UIConnection .overlay {
        margin-top: -1230px;
        height: 1980px;
    }

    .UIConnection .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }
    .UIConnection .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }
    .UIConnection .container.hero-image {
        margin-top: -250px;
    }
    .UIConnection .container .heading {
        padding-bottom: 80px;
    }

}

@media (min-width : 768px) and (max-width: 991.98px) {
    .UIConnection form .row .form-element input, .UIConnection form .row .form-element textarea {
        width: 100%;
    }
    .UIConnection .container-fluid.background {
        padding: 20vw 0 36vw;
        position: relative;
    }

    .UIConnection .overlay {
        margin-top: -1290px;
    height: 1950px;
    }

    .UIConnection .container-fluid.background .bg-image {
        left: -20%;
        right: -20%;
    }
    .UIConnection .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }
    .UIConnection .container.hero-image {
        margin-top: -330px;
    }
}

@media (min-width : 992px) and (max-width: 1199.98px) {
    .UIConnection form .row .form-element input, .UIConnection form .row .form-element textarea {
        width: 100%;
    }
    .UIConnection .container-fluid.background {
        padding:20vw 0 28vw;
        position: relative;
    }

    .UIConnection .overlay {
        margin-top: -1310px;
    height: 1950px;
    }

    .UIConnection .container-fluid.background .bg-image {
        left: -20%;
    right: -20%;
    }
    .UIConnection .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }
    .UIConnection .container.hero-image {
        margin-top: -400px;
    }
}

@media (min-width : 1200px) and (max-width: 1399.98px) {
    .UIConnection form .row .form-element input, .UIConnection form .row .form-element textarea {
        width: 100%;
    }
    .UIConnection .container-fluid.background {
        padding: 23vw 0 18vw;
        position: relative;
    }

    .UIConnection .overlay {
        margin-top: -1350px;
        height: 1960px;
    }

    .UIConnection .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }
    .UIConnection .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }
    .UIConnection .container.hero-image {
        margin-top: -300px;


        &[class^="en_"] {
            margin-top: -200px !important;
        }

        &[class^="sl_"] {
            margin-top: -200px !important;
        }
    }
}

@media (min-width : 1400px) and (max-width: 2000px) {
    .UIConnection form .row .form-element input, .UIConnection form .row .form-element textarea {
        width: 100%;
    }
    .UIConnection .container-fluid.background {
        padding: 250px 0 220px;
        position: relative;
    }
    .UIConnection .overlay {
        margin-top: -1250px;
        height: 1900px;
    }
    .UIConnection .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }
    .UIConnection .container.hero-image {
        margin-top: -330px;
    }
    .UIConnection .container-fluid.background .bg-image {
        left: -10%;
        right: -10%;
    }    
}