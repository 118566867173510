.UISupplyCompanies {
  width: 100%;
  z-index: -1;
  margin-top: -140px;
  overflow: hidden;
}

.UISupplyCompanies .container-fluid.background {
  padding: 400px 0 400px;
  position: relative;
}

.UISupplyCompanies .container-fluid.background .bg-image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.UISupplyCompanies video.header-video {
  width: 100%;
  height: auto;
  position: absolute;
}

.UISupplyCompanies .container.hero-image {
  margin-top: -400px;
  z-index: 9;
  position: relative;

  img {
      width: fit-content;
      margin-left: -21%;
  }
}

.UISupplyCompanies .container.content {
  margin: 50px auto 100px;
}

.UISupplyCompanies .container.video {
  margin: 0 auto 5vw;
  text-align: center;

  video#video {
      border-radius: 20px;
  }
}

.UISupplyCompanies .overlay {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: -1;
  margin: -1260px 0 -530px;
}

.UISupplyCompanies .supply_icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .icon {
      flex: 0 0 calc(25% - 10px);
      text-align: center;
  }

  img.icon_image {
      width: 60%;
  }

}

.UISupplyCompanies .boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .box {
      margin: 1%;
      flex: 0 0 48%;

      >img {
          height: min-content;
          margin: 0 auto 40px auto;
      }

      &.small_boxes {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          >.small_box {
              >img {
                  margin: auto 10px auto 0;
              }

              align-items: center;
              border-radius: 10px;
              padding: 35px;
              flex: 0 0 30%;
              display: flex;
              flex-direction: row;
              justify-content: start;

              &:not(:last-child) {
                  margin: 0 0 20px 0;
              }

              .heading {
                  text-align: start;
              }
          }
      }
  }

  >:first-child {
      border-radius: 10px;
      padding: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
  }
}

.greybg {
  background-color: #e9e9ea;
}

.UISupplyCompanies .functions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .function {
      background-color: #232832;
      flex: 0 0 31%;
      position: relative;
      padding: 25px 35px;
      border-radius: 10px;

      .function_number {
          position: absolute;
          background-color: #5dbb46;
          border-radius: 100px;
          width: 40px;
          height: 40px;
          left: -20px;
          top: 50px;

          span {
              color: white;
              margin-left: 40%;
              line-height: 2.4;
              position: relative;
          }
      }

      .function_title {
          text-align: start;
          color: #a5a5a5;

          h4 {
              color: white;
          }
      }

  }
}

.UISupplyCompanies .meter_box {
  border-radius: 10px;
  padding: 20px 30px;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;

  .box_row {
      a img {
          margin: 10px 10px 10px 0;
      }
  }
}

.UISupplyCompanies .change {
  .card {
      position: relative;
      height: fit-content;
      border: none;
      height: 575px;

      img {
          width: 100%;
          position: absolute;
      }

      .card-text {
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          z-index: 1;
          width: 40%;
          color: #a5a5a5;
          padding: 40px;

          h3 {
              color: white;
          }

          .btn {
              display: block;
              margin: 10px 0;
              max-width: 300px;
              padding: 15px 30px;
          }
      }
  }
}

.UISupplyCompanies .files {
  .files_holder {
      padding: 0 30px;

      .file {
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
              flex: 0 0 10%;
          }

          p {
              flex: 0 1 90%;
              margin-bottom: 0;
          }

      }

      &.files-left {
          .file {
              flex-direction: row;
              text-align: right;
          }
      }

      &.files-right {
          .file {
              text-align: left;
              flex-direction: row-reverse;
          }
      }
  }
}

@media (max-width: 575.98px) {
  .UISupplyCompanies .container-fluid.background .bg-image {
      left: -50%;
      right: -50%;
  }

  .UISupplyCompanies .container-fluid.background {
      padding: 40vw 0 70vw;
      position: relative;
  }

  .UISupplyCompanies .overlay {
      margin-top: -1240px;
      height: 1720px;
  }

  .UISupplyCompanies .container.hero-image img {
      width: 120%;
      margin: 0 50%;
      transform: translateX(-50%);
  }

  .UISupplyCompanies .container.hero-image {
      margin-top: -260px;
  }

  .UISupplyCompanies .supply_icons {
      .icon {
          flex: 0 0 250px;
      }
  }

  .UISupplyCompanies .boxes .box {
      flex: 0 0 100%;
  }

  .UISupplyCompanies .functions .function {
      flex: 0 0 calc(100% - 20px);
      margin: 10px;
  }

  .UISupplyCompanies .change .card {
      height: 240px;
      .card-text{
          width: 50%;
      }
      p{
          display: none;
      }
  }
  .UISupplyCompanies .files .files_holder.files-left .file {
      flex-direction: row-reverse;
      text-align: left;
      }
}

@media (min-width : 576px) and (max-width: 767.98px) {
  .UISupplyCompanies .container-fluid.background .bg-image {
      left: -50%;
      right: -50%;
  }

  .UISupplyCompanies .container-fluid.background {
      padding: 25vw 0 33vw;
      position: relative;
  }

  .UISupplyCompanies .overlay {
      margin-top: -1220px;
      height: 1740px;
  }

  .UISupplyCompanies .container.hero-image img {
      width: 120%;
      margin: 0 50%;
      transform: translateX(-50%);
  }

  .UISupplyCompanies .container.hero-image {
      margin-top: -170px;
  }
  .UISupplyCompanies .supply_icons {
      .icon {
          flex: 0 0 250px;
      }
  }

  .UISupplyCompanies .boxes .box {
      flex: 0 0 100%;
  }

  .UISupplyCompanies .functions .function {
      flex: 0 0 calc(100% - 20px);
      margin: 10px;
  }

  .UISupplyCompanies .change .card {
      height: 240px;
      .card-text{
          width: 70%;
      }
      p{
          display: none;
      }
  }
  .UISupplyCompanies .files .files_holder.files-left .file {
      flex-direction: row-reverse;
      text-align: left;
      }
}

@media (min-width : 768px) and (max-width: 991.98px) {
  .UISupplyCompanies .container-fluid.background .bg-image {
      left: -50%;
      right: -50%;
  }

  .UISupplyCompanies .container-fluid.background {
      padding: 35vw 0 32vw;
      position: relative;
  }

  .UISupplyCompanies .overlay {
      margin-top: -1240px;
      height: 1780px;
  }

  .UISupplyCompanies .container.hero-image img {
      width: 120%;
      margin: 0 50%;
      transform: translateX(-50%);
  }

  .UISupplyCompanies .container.hero-image {
      margin-top: -180px;
  }

  .UISupplyCompanies .supply_icons {
      .icon {
          flex: 0 0 250px;
      }
  }

  .UISupplyCompanies .boxes .box {
      flex: 0 0 100%;
  }

  .UISupplyCompanies .functions .function {
      flex: 0 0 calc(100% - 20px);
      margin: 10px;
  }

  .UISupplyCompanies .change .card {
      height: 240px;
      .card-text{
          width: 50%;
      }
      p{
          display: none;
      }
  }
  .UISupplyCompanies .files .files_holder.files-left .file {
      flex-direction: row-reverse;
      text-align: left;
      }

}

@media (min-width : 992px) and (max-width: 1199.98px) {
  .UISupplyCompanies .container-fluid.background .bg-image {
      left: -40%;
      right: -40%;
  }

  .UISupplyCompanies .container-fluid.background {
      position: relative;
      padding: 30vw 0 20vw;
  }

  .UISupplyCompanies .overlay {
      margin-top: -1250px;
      height: 1730px;
  }

  .UISupplyCompanies .container.hero-image img {
      width: 120%;
      margin: 0 50%;
      transform: translateX(-50%);
  }

  .UISupplyCompanies .container.hero-image {
      margin-top: -60px;
  }
  .UISupplyCompanies .smart_meter {
      width: 100%;
  }
  .UISupplyCompanies .change .card .card-text {
      transform: translateY(-70%);
      width: 70%;
      }
}

@media (min-width : 1200px) and (max-width: 1399.98px) {
  .UISupplyCompanies .container-fluid.background .bg-image {
      left: -30%;
      right: -30%;
  }

  .UISupplyCompanies .container-fluid.background {
      padding: 23vw 0 18vw;
      position: relative;
  }

  .UISupplyCompanies .overlay {
      margin-top: -1270px;
      height: 1780px;
  }

  .UISupplyCompanies .container.hero-image img {
      width: 120%;
      margin: 0 50%;
      transform: translateX(-50%);
  }

  .UISupplyCompanies .container.hero-image {
      margin-top: -150px;
  }
}

@media (min-width : 1400px) and (max-width: 2000px) {
  .UISupplyCompanies .container-fluid.background {
      padding: 250px 0 220px;
      position: relative;
  }

  .UISupplyCompanies .overlay {
      margin-top: -1280px;
      height: 1780px;
  }

  .UISupplyCompanies .container.hero-image img {
      width: 120%;
      margin: 0 50%;
      transform: translateX(-50%);
  }

  .UISupplyCompanies .container.hero-image {
      margin-top: -250px;
  }
}