.UIProducts {
    width: 100%;
    z-index: -1;
    margin-top: -140px;
}

.UIProducts .container-fluid.background {
    background-color: #373f4d;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#fff));
    background-image: linear-gradient(#222731, #fff);
    padding: 260px 0 350px;
}

.UIProducts .container-fluid.background .bg-image {
    background-image: url('/public/images/whoweare-header-bg.png');
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.UIProducts .heading {
    text-align: center;
}

.UIProducts .heading h2 {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #5eba47;
}

.UIProducts .heading h3 {
    font-size: 72px;
    font-weight: 700;
    color: #373f4d;
}

.UIProducts .content .text {
    width: 70%;
    margin: 0 auto;
    text-align: justify;

    p {
        color: #373f4d;
        text-align: justify;
    }
}

.UIProducts .container.content {
    margin-bottom: 100px;

    .heading {
        margin: 100px 0 50px;
    }
}

.UIProducts .heading h1 {
    font-size: 85px;
    font-weight: 700;
    color: #fff;
}

.UIProducts .container .card {
    border: none;
    background-color: transparent;
    margin-bottom: 50px;
    padding: 0 60px;

    img {
        visibility: hidden;
    }
}

.UIProducts .container .card#tso {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/public/images/cards/tso.png');
}

.UIProducts .container .card#companies {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/public/images/cards/companies.png');
}

.UIProducts .container .card#households {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/public/images/cards/households.png');
}
.UIProducts .container .card#partners {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/public/images/cards/partners.png');
}

.UIProducts .card-content {
    position: absolute;
    bottom: 50px;
    left: 90px;
    width: 50%;
    color: #fff;

    h4 {
        font-size: 55px;
    }
}
.UIProducts .container.cards-container {
    margin-top: -200px;
}

@media (max-width: 575.98px) { 
    .UIProducts .heading h1,
    .UIProducts .heading h3 {
        font-size: 50px;
    }
    .UIProducts .heading h2 {
        font-size: 20px;
    }
    .UIProducts .card-content h4 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .UIProducts .card-content p {
        display: none;
    }
    .UIProducts .container-fluid.background {
        padding: 180px 0 280px;
    }
    .UIProducts .container .card {
        padding: 0;
    }
    .UIProducts .card-content {
        bottom: 20px;
        left: 20px;
        width: 90%;
    }
    .UIProducts .content .text {
        width: 90%;
    }
    .UIProducts .container.cards-container {
        margin-top: -200px;
    }
}
@media (min-width : 576px) and (max-width: 767.98px) { 
    .UIProducts .heading h1,
    .UIProducts .heading h3 {
        font-size: 60px;
    }
    .UIProducts .heading h2 {
        font-size: 25px;
    }
    .UIProducts .card-content h4 {
        font-size: 35px;
    }
    .UIProducts .container-fluid.background {
        padding: 180px 0 300px;
    }
    .UIProducts .container .card {
        padding: 0;
    }
    .UIProducts .card-content {
        bottom: 30px;
        left: 20px;
        width: 90%;
    }
    .UIProducts .content .text {
        width: 90%;
    }
    .UIProducts .container.cards-container {
        margin-top: -200px;
    }
}
@media (min-width : 768px) and (max-width: 991.98px) { 
    .UIProducts .heading h1,
    .UIProducts .heading h3 {
        font-size: 60px;
    }
    .UIProducts .heading h2 {
        font-size: 25px;
    }
    .UIProducts .card-content h4 {
        font-size: 35px;
    }
    .UIProducts .container-fluid.background {
        padding: 180px 0 300px;
    }
    .UIProducts .container .card {
        padding: 0;
    }
    .UIProducts .card-content {
        bottom: 30px;
        left: 20px;
        width: 70%;
    }
    .UIProducts .content .text {
        width: 80%;
    }
    .UIProducts .container.cards-container {
        margin-top: -200px;
    }
}

@media (min-width : 992px) and (max-width: 1199.98px) { 
    .UIProducts .heading h1,
    .UIProducts .heading h3 {
        font-size: 65px;
    }
    .UIProducts .heading h2 {
        font-size:25px;
    }
    .UIProducts .card-content h4 {
        font-size: 40px;
    }
    .UIProducts .container-fluid.background {
        padding: 180px 0 300px;
    }
    .UIProducts .container .card {
        padding: 0 40px;
    }
    .UIProducts .card-content {
        bottom: 40px;
        left: 70px;
        width: 60%;
    }
    .UIProducts .content .text {
        width: 80%;
    }
    .UIProducts .container.cards-container {
    margin-top: -200px;
}
}
@media (min-width : 1200px) and (max-width: 1399.98px) { 
    .UIProducts .heading h1,
    .UIProducts .heading h3 {
        font-size: 70px;
    }
    .UIProducts .heading h2 {
        font-size: 25px;
    }
    .UIProducts .card-content h4 {
        font-size: 50px;
    }
    .UIProducts .container-fluid.background {
        padding: 180px 0 300px;
    }
    .UIProducts .container .card {
        padding: 0 50px;
    }
    .UIProducts .card-content {
        bottom: 40px;
        left: 80px;
        width: 60%;
    }
    .UIProducts .content .text {
        width: 70%;
    }
    .UIProducts .container.cards-container {
        margin-top: -200px;
    }
}