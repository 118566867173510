.footer {
  background: #232833;
  background-image: url("../../public/images/footer-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 60px 0;
  position: relative;
}
.footer .row.fmenu {
  margin: 20px 0;
}
.footer p {
  color: #c4cee1;
}
.footer a.twitterlink {
  color: #c4cee1;
  font-size: 30px;
}

.footer .footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.footer .footer-menu a.footer-link {
  padding: 0 15px;
  text-decoration: none;
  text-transform: uppercase;
  color: #c4cee1;
  font-weight: 500;
}
.footer .footer-menu a.footer-link:hover {
  color: #67bc4f;
}
.footer .footer-menu a.footer-link-bottom {
  padding: 0 15px;
  text-decoration: none;
  text-transform: none;
  color: #c4cee1;
  font-weight: 400;
}
.footer .footer-menu a.footer-link-bottom:hover {
  color: #67bc4f;
}

.footer .row.center {
  place-content: center;
}

.footer span.status-on {
  color: #67bc4f;
  font-weight: 500;
}

.footer gdot {
  display: block;
  height: 5px;
  width: 5px;
  background: #67bc4f;
  border-radius: 100%;
  margin: auto 0;
}

.footer .hidden {
  display: none;
}

@media (max-width: 575.98px) {
  .footer .container {
    max-width: 100%;
    line-height: 30px;
  }
  .footer .footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
  }
  .footer gdot {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .footer .container {
    max-width: 100%;
  }
  .footer gdot {
    display: none;
  }
  .footer .footer-menu {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .footer .container {
    max-width: 100%;
  }
  .footer .footer-menu {
    display: flex;
    flex-wrap: wrap;
  }
}