.UIBBSS {
  width: 100%;
  z-index: -1;
  margin-top: -140px;
  overflow: hidden;
}

.UIBBSS .container-fluid.background {
  padding: 250px 0 220px;
  position: relative;
}

.UIBBSS .container-fluid.background .bg-image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.UIBBSS video.header-video {
  width: 100%;
  height: auto;
  position: absolute;
}

.UIBBSS .container.hero-image {
  margin-top: -200px;
  z-index: 9;
  position: relative;
}
.UIBBSS .container.hero-image img {
  width: inherit;
}

.UIBBSS .container.content {
  margin: 100px auto;
  padding: 0 10vw;
}

.UIBBSS .container.video {
  margin: 0 auto 5vw;
  text-align: center;
}
.UIBBSS .container.video video#video {
  border-radius: 20px;
}

.UIBBSS .overlay {
  overflow: hidden;
  display: block;
  position: relative;
  z-index: -1;
  margin: -900px 0;
}

@media (max-width: 575.98px) {
  .UIBBSS .container-fluid.background {
    padding: 40vw 0 70vw;
    position: relative;
  }
  .UIBBSS .overlay {
    margin-top: -270px;
    height: 1130px;
  }
  .UIBBSS .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UIBBSS .container-fluid.background {
    padding: 25vw 0 33vw;
    position: relative;
  }
  .UIBBSS .overlay {
    margin-top: -310px;
    height: 1200px;
  }
  .UIBBSS .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UIBBSS .container-fluid.background {
    padding: 25vw 0 33vw;
    position: relative;
  }
  .UIBBSS .overlay {
    margin-top: -420px;
    height: 1300px;
  }
  .UIBBSS .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .UIBBSS .container-fluid.background {
    padding: 25vw 0 33vw;
    position: relative;
  }
  .UIBBSS .overlay {
    margin-top: -530px;
    height: 1400px;
  }
  .UIBBSS .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .UIBBSS .container-fluid.background {
    padding: 25vw 0 33vw;
    position: relative;
  }
  .UIBBSS .overlay {
    margin-top: -630px;
    height: 1500px;
  }
  .UIBBSS .container-fluid.background .bg-image {
    left: -50%;
    right: -50%;
  }
}