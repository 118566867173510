#preloader {
    position: absolute;
    top: 0%;
    z-index: 999;
    width: 100vw;
    overflow: hidden;
    bottom: 0;
    background-color: black;
    video {
        width: 100%;
        height: inherit;
    }
}

a.intro-skip {
    position: absolute;
    bottom: 50px;
    right: 50px;
    color: white;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
}

a.intro-skip:hover {
    transform: scale(1.1);
}

.unmute-icon {
    position: fixed!important;
    top: 50px!important;
    right: 50px!important;
    z-index: 99999!important;
    border: none!important;
    background: none!important;
    width: 50px;
    filter: brightness(5);
}
.unmute-icon:hover {
    transform: scale(1.2);
    filter: brightness(1);
}