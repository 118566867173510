.UISupplyHouseholds {
    width: 100%;
    z-index: -1;
    margin-top: -140px;
    overflow: hidden;
}

.UISupplyHouseholds .container-fluid.background {
    padding: 400px 0 400px;
    position: relative;
}

.UISupplyHouseholds .container-fluid.background .bg-image {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.UISupplyHouseholds video.header-video {
    width: 100%;
    height: auto;
    position: absolute;
}

.UISupplyHouseholds .container.hero-image {
    margin-top: -400px;
    z-index: 9;
    position: relative;

    img {
        width: fit-content;
        margin-left: -21%;
    }
}

.pricelist_small_title {
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 700;
    color: #c1c1c1
}

.UISupplyHouseholds .function.package:hover {
    background-color: #5eba47;

    * {
        color: #fff;
    }

    ul li::marker {
        color: #fff;
    }
}

.UISupplyHouseholds .function.package {
    ul li::marker {
        color: #5eba47;
    }
}

.package-link {
    display: contents;
}

.UISupplyHouseholds .functions {
    margin-top: 80px;

    .package {
        box-shadow: 0 0 13px 7px rgba(0, 0, 0, 0.49);
    }

    >a:nth-child(1) .package {
        flex: 0 1 350px;
    }

    >a:nth-child(2) .package {
        flex: 0 1 500px;
        margin-top: -30px;
        margin-bottom: -30px;
        position: relative;
        background-color: #5eba47;
        ul li, p, a, span, small {
            color: white;
        }
        li::marker {
            color: #fff;
        }
        .readmore {

            a {
                border-bottom: 3px solid #fff;
            }

        }

        &:hover {
            ul li, p, a, span, small {
                color: unset;
            }
            li::marker {
                color: #5eba47;
            }
            background-color: #232832;
            .readmore {

                a {
                    border-bottom: 3px solid #5eba47;
                }
    
            }
        }

        .ribbon {
            --f: 5px;
            
            position: absolute;
            top: 0;
            color: #fff;
            font-weight: 700;
            padding: .1em 1.8em;
            background: var(--c,#232832);
            border-bottom :var(--f) solid #0007;
            clip-path: polygon(
              100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px))
          }
          .right {
            right: 0;
            transform: translate(calc((1 - cos(45deg))*100%), -100%) rotate(45deg);
            transform-origin: 0% 100%;
          }
          
          /* a fix for firefox that show some strange lines*/
          @supports (-moz-appearance:none) {
            .ribbon {
              background:
                linear-gradient(to top,#0000 1px,#0005 0 var(--f),#0000 0) border-box,
                linear-gradient(var(--c,#45ADA8) 0 0) 50%/
                 calc(100% - 2px) calc(100% - 2px) no-repeat border-box;
              border-bottom-color: #0000;
             }
          }
    }

    >a:nth-child(3) .package {
        flex: 0 1 350px;
    }
}

.UISupplyHouseholds .container.content {
    margin: 50px auto 100px;
}

.UISupplyHouseholds .container.video {
    margin: 0 auto 5vw;
    text-align: center;

    video#video {
        border-radius: 20px;
    }
}

.UISupplyHouseholds .overlay {
    overflow: hidden;
    display: block;
    position: relative;
    z-index: -1;
    margin: -1260px 0 -530px;
}

.UISupplyHouseholds .supply_icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .icon {
        flex: 0 0 calc(25% - 10px);
        text-align: center;
    }

    img.icon_image {
        width: 60%;
    }

}

.UISupplyHouseholds .boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .box {
        margin: 1%;
        flex: 0 0 48%;

        >img {
            height: min-content;
            margin: 0 auto 40px auto;
        }

        &.small_boxes {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            >.small_box {
                >img {
                    margin: auto 10px auto 0;
                }

                align-items: center;
                border-radius: 10px;
                padding: 35px;
                flex: 0 0 30%;
                display: flex;
                flex-direction: row;
                justify-content: start;

                &:not(:last-child) {
                    margin: 0 0 20px 0;
                }

                .heading {
                    text-align: start;
                }
            }
        }
    }

    >:first-child {
        border-radius: 10px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
    }
}

.greybg {
    background-color: #e9e9ea;
}

.UISupplyHouseholds .functions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .function {
        background-color: #232832;
        flex: 0 0 31%;
        position: relative;
        padding: 25px 35px;
        border-radius: 10px;
        transition: .2s linear;

        .function_number {
            position: absolute;
            background-color: #5eba47;
            border-radius: 100px;
            width: 40px;
            height: 40px;
            left: -20px;
            top: 50px;

            span {
                color: white;
                margin-left: 40%;
                line-height: 2.4;
                position: relative;
            }
        }

        .readmore {
            position: absolute;
            bottom: 30px;
            right: 20px;

            a {
                font-size: 14px;
                color: white;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 500;
                border-bottom: 3px solid #5eba47;
                padding-bottom: 3px;
            }

        }

        .function_title {
            text-align: start;
            color: #a5a5a5;

            h4 {
                color: white;

                &.package_title {
                    font-size: 22px;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }
        }

        &.package {}
    }
}

.UISupplyHouseholds .meter_box {
    border-radius: 10px;
    padding: 20px 30px;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;

    .box_row {
        a img {
            margin: 10px 10px 10px 0;
        }
    }
}

.UISupplyHouseholds .change {
    .card {
        position: relative;
        height: fit-content;
        border: none;
        height: 575px;

        img {
            width: 100%;
            position: absolute;
        }

        .card-text {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            z-index: 1;
            width: 40%;
            color: #a5a5a5;
            padding: 40px;

            h3 {
                color: white;
            }

            .btn {
                display: block;
                margin: 10px 0;
                max-width: 300px;
                padding: 15px 30px;
            }
        }
    }
}

.UISupplyHouseholds .files {
    .files_holder {
        padding: 0 30px;

        .file {
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                flex: 0 0 10%;
            }

            p {
                flex: 0 1 90%;
                margin-bottom: 0;
            }

        }

        &.files-left {
            .file {
                flex-direction: row;
                text-align: right;
            }
        }

        &.files-right {
            .file {
                text-align: left;
                flex-direction: row-reverse;
            }
        }
    }
}

@media (max-width: 575.98px) {

    .UISupplyHouseholds .functions {
        margin-top: 80px;

        >a:nth-child(1) .package {
            flex: 1 1 350px;
        }

        >a:nth-child(2) .package {
            flex: 1 1 500px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        >a:nth-child(3) .package {
            flex: 1 1 350px;
        }
    }

    .UISupplyHouseholds .container-fluid.background .bg-image {
        left: -50%;
        right: -50%;
    }

    .UISupplyHouseholds .container-fluid.background {
        padding: 40vw 0 70vw;
        position: relative;
    }

    .UISupplyHouseholds .overlay {
        margin-top: -1240px;
        height: 1720px;
    }

    .UISupplyHouseholds .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }

    .UISupplyHouseholds .container.hero-image {
        margin-top: -260px;
    }

    .UISupplyHouseholds .supply_icons {
        .icon {
            flex: 0 0 250px;
        }
    }

    .UISupplyHouseholds .boxes .box {
        flex: 0 0 100%;
    }

    .UISupplyHouseholds .functions .function {
        flex: 0 0 calc(100% - 20px);
        margin: 10px;
    }

    .UISupplyHouseholds .change .card {
        height: 240px;

        .card-text {
            width: 50%;
        }

        p {
            display: none;
        }
    }

    .UISupplyHouseholds .files .files_holder.files-left .file {
        flex-direction: row-reverse;
        text-align: left;
    }
}

@media (min-width : 576px) and (max-width: 767.98px) {
    .UISupplyHouseholds .container-fluid.background .bg-image {
        left: -50%;
        right: -50%;
    }

    .UISupplyHouseholds .functions {
        margin-top: 80px;

        >a:nth-child(1) .package {
            flex: 1 1 350px;
        }

        >a:nth-child(2) .package {
            flex: 1 1 500px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        >a:nth-child(3) .package {
            flex: 1 1 350px;
        }
    }

    .UISupplyHouseholds .container-fluid.background {
        padding: 25vw 0 33vw;
        position: relative;
    }

    .UISupplyHouseholds .overlay {
        margin-top: -1220px;
        height: 1740px;
    }

    .UISupplyHouseholds .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }

    .UISupplyHouseholds .container.hero-image {
        margin-top: -170px;
    }

    .UISupplyHouseholds .supply_icons {
        .icon {
            flex: 0 0 250px;
        }
    }

    .UISupplyHouseholds .boxes .box {
        flex: 0 0 100%;
    }

    .UISupplyHouseholds .functions .function {
        flex: 0 0 calc(100% - 20px);
        margin: 10px;
    }

    .UISupplyHouseholds .change .card {
        height: 240px;

        .card-text {
            width: 70%;
        }

        p {
            display: none;
        }
    }

    .UISupplyHouseholds .files .files_holder.files-left .file {
        flex-direction: row-reverse;
        text-align: left;
    }
}

@media (min-width : 768px) and (max-width: 991.98px) {

    .UISupplyHouseholds .functions {
        margin-top: 80px;

        >a:nth-child(1) .package {
            flex: 1 1 350px;
        }

        >a:nth-child(2) .package {
            flex: 1 1 500px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        >a:nth-child(3) .package {
            flex: 1 1 350px;
        }
    }

    .UISupplyHouseholds .container-fluid.background .bg-image {
        left: -50%;
        right: -50%;
    }

    .UISupplyHouseholds .container-fluid.background {
        padding: 35vw 0 32vw;
        position: relative;
    }

    .UISupplyHouseholds .overlay {
        margin-top: -1240px;
        height: 1780px;
    }

    .UISupplyHouseholds .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }

    .UISupplyHouseholds .container.hero-image {
        margin-top: -180px;
    }

    .UISupplyHouseholds .supply_icons {
        .icon {
            flex: 0 0 250px;
        }
    }

    .UISupplyHouseholds .boxes .box {
        flex: 0 0 100%;
    }

    .UISupplyHouseholds .functions .function {
        flex: 0 0 calc(100% - 20px);
        margin: 10px;
    }

    .UISupplyHouseholds .change .card {
        height: 240px;

        .card-text {
            width: 50%;
        }

        p {
            display: none;
        }
    }

    .UISupplyHouseholds .files .files_holder.files-left .file {
        flex-direction: row-reverse;
        text-align: left;
    }

}

@media (min-width : 992px) and (max-width: 1199.98px) {
    .UISupplyHouseholds .container-fluid.background .bg-image {
        left: -40%;
        right: -40%;
    }

    .UISupplyHouseholds .container-fluid.background {
        position: relative;
        padding: 30vw 0 20vw;
    }

    .UISupplyHouseholds .overlay {
        margin-top: -1250px;
        height: 1730px;
    }

    .UISupplyHouseholds .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }

    .UISupplyHouseholds .container.hero-image {
        margin-top: -60px;
    }

    .UISupplyHouseholds .smart_meter {
        width: 100%;
    }

    .UISupplyHouseholds .change .card .card-text {
        transform: translateY(-70%);
        width: 70%;
    }

    .UISupplyHouseholds .functions {
        margin-top: 80px;

        >a:nth-child(1) .package {
            flex: 0 1 250px;
        }

        >a:nth-child(2) .package {
            flex: 0 1 350px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        >a:nth-child(3) .package {
            flex: 0 1 250px;
        }
    }
}

@media (min-width : 1200px) and (max-width: 1399.98px) {
    .UISupplyHouseholds .container-fluid.background .bg-image {
        left: -30%;
        right: -30%;
    }

    .UISupplyHouseholds .container-fluid.background {
        padding: 23vw 0 18vw;
        position: relative;
    }

    .UISupplyHouseholds .overlay {
        margin-top: -1270px;
        height: 1780px;
    }

    .UISupplyHouseholds .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }

    .UISupplyHouseholds .container.hero-image {
        margin-top: -150px;
    }

    .UISupplyHouseholds .functions {
        margin-top: 80px;

        >a:nth-child(1) .package {
            flex: 0 1 250px;
        }

        >a:nth-child(2) .package {
            flex: 0 1 350px;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        >a:nth-child(3) .package {
            flex: 0 1 250px;
        }
    }
}

@media (min-width : 1400px) and (max-width: 2000px) {
    .UISupplyHouseholds .container-fluid.background {
        padding: 250px 0 220px;
        position: relative;
    }

    .UISupplyHouseholds .overlay {
        margin-top: -1280px;
        height: 1780px;
    }

    .UISupplyHouseholds .container.hero-image img {
        width: 120%;
        margin: 0 50%;
        transform: translateX(-50%);
    }

    .UISupplyHouseholds .container.hero-image {
        margin-top: -250px;
    }
}