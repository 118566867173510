.UIHome {
  width: 100%;
  z-index: -1;
  margin-top: -140px;
  overflow: hidden;
}

.UIHome .container-fluid.header {
  padding: 0;
  position: inherit;
  background-color: #fff;
  margin-bottom: -6px;
}
.UIHome .container-fluid.header video {
  width: 100%;
  height: auto;
  background-color: #fff;
}

.UIHome .bigwhoweare {
  position: relative;
  overflow: hidden;
  height: 142vh;
  padding: 5vw 0;
  margin: 0vw 0 0;
}
.UIHome .bigwhoweare .bg-image {
  left: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
}
.UIHome .bigwhoweare .container.whoweare {
  padding: 5vw 0;
}

.UIHome .bigwhatwedo, UIHome .bigNgenStar {
  position: relative;
  overflow: hidden;
  height: 130vh;
  padding: 5vw 0;
  margin: -5vw 0 0;
}
.UIHome .bigwhatwedo .bg-image, UIHome .bigNgenStar .bg-image {
  left: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
}
.UIHome .bigwhatwedo .container.whatwedo, .UIHome .bigwhatwedo .container.ngenstar, UIHome .bigNgenStar .container.whatwedo, UIHome .bigNgenStar .container.ngenstar {
  padding: 5vw 0;
}

.UIHome .heading {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.UIHome .heading h2 {
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  color: #5eba47;
}

.UIHome .heading h1 {
  font-size: 85px;
  font-weight: 700;
  color: #fff;
  overflow: hidden;
  border-right: 0.15em solid transparent;
  white-space: nowrap;
  max-width: fit-content;
  margin: 0 auto;
  letter-spacing: 0;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end 3;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #5eba47;
  }
}
.UIHome .cards {
  margin: 100px auto;
}
.UIHome .cards .heading h3 {
  font-size: 55px;
  color: #373f4d;
  font-weight: 700;
}
.UIHome .cards .heading h4 {
  font-size: 16px;
  color: #5eba47;
  text-transform: uppercase;
  font-weight: 700;
}

.UIHome .row.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.UIHome .row.cards .card {
  border: none;
  border-radius: 20px;
  background-color: #222731;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#222731), to(#373f4d));
  background-image: linear-gradient(#222731, #373f4d);
  margin: 0 30px;
}
.UIHome .row.cards .card:hover {
  border: none;
  border-radius: 20px;
  background-color: #222731;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#204017), to(#5eba47));
  background-image: linear-gradient(#204017, #5eba47);
}
.UIHome .row.cards .readmore {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
.UIHome .row.cards .readmore a {
  font-size: 14px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 3px solid #5eba47;
  padding-bottom: 3px;
}
.UIHome .row.cards .card-link:hover a {
  border-bottom: 3px solid #fff;
}
.UIHome .row.cards .card-shadow {
  margin: 40px 0 20px;
}
.UIHome .row.cards .card-text {
  position: absolute;
  padding: 50px 20px 0;
  inline-size: -webkit-fill-available;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
}
.UIHome .row.cards .card-text h3 {
  color: white;
  font-size: 40px;
}
.UIHome .row.cards .card-text h4 {
  color: #5eba47;
  font-size: 14px;
  text-transform: uppercase;
}

.UIHome .container.cards .heading {
  text-align: center;
  width: 100%;
  position: relative;
  margin-bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.UIHome .container.whoweare .heading,
.UIHome .container.whatwedo .heading,
.UIHome .container.ngenstar .heading {
  text-align: left;
  width: 100%;
  position: relative;
  float: left;
}
.UIHome .container.whoweare .heading h3,
.UIHome .container.whatwedo .heading h3,
.UIHome .container.ngenstar .heading h3 {
  font-size: 60px;
  color: #373f4d;
  font-weight: 700;
}
.UIHome .container.whoweare .heading h4,
.UIHome .container.whatwedo .heading h4,
.UIHome .container.ngenstar .heading h4 {
  font-size: 16px;
  color: #5eba47;
  text-transform: uppercase;
  font-weight: 700;
}

.UIHome img.whoweare {
  width: 130%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -5%;
  z-index: -1;
}

.UIHome img.whatwedo, .UIHome img.ngenstar {
  width: 100%;
  margin-top: 0%;
  z-index: -1;
}

@media (max-width: 575.98px) {
  .UIHome .container-fluid.header video {
    width: 120%;
    position: relative;
    left: -10%;
    margin-top: 0;
    height: 100%;
  }
  .UIHome .heading {
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
  .UIHome .heading h1 {
    font-size: 35px;
  }
  .UIHome .heading h2 {
    font-size: 15px;
  }
  .UIHome .container.whoweare .heading,
  .UIHome .container.whatwedo .heading,
  .UIHome .container.ngenstar .heading {
    width: 90%;
  }
  .UIHome .container.whoweare .heading h3,
  .UIHome .container.whatwedo .heading h3,
  .UIHome .container.ngenstar .heading h3 {
    font-size: 35px;
  }
  .UIHome .container.whoweare .heading h4,
  .UIHome .container.whatwedo .heading h4,
  .UIHome .container.ngenstar .heading h4 {
    font-size: 15px;
  }
  .UIHome .container.cards .heading {
    width: 100%;
    padding: 0 0 20px;
  }
  .UIHome .container.cards .heading h3 {
    font-size: 35px;
  }
  .UIHome .container.cards .heading h4 {
    font-size: 15px;
  }
  .UIHome .bigwhoweare .container.whoweare {
    padding: 0;
  }
  .UIHome .cards {
    margin: 40px auto 40px;
  }
  .UIHome .cards .card-shadow img {
    width: 100%;
  }
  .UIHome .bigwhoweare {
    height: auto;
  }
  .UIHome img.whoweare {
    width: 100%;
    margin-top: 30%;
  }
  .UIHome img.whatwedo, .UIHome img.ngenstar {
    width: 90%;
    margin-top: 20%;
  }
  .UIHome .bigwhatwedo, UIHome .bigNgenStar {
    height: auto;
  }
  .UIHome .row.cards .card-text h3 {
    font-size: 30px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .UIHome .container-fluid.header video {
    width: 120%;
    position: relative;
    left: -10%;
    margin-top: 0;
    height: 100%;
  }
  .UIHome .heading {
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
  .UIHome .heading h1 {
    font-size: 35px;
  }
  .UIHome .heading h2 {
    font-size: 15px;
  }
  .UIHome .container.whoweare .heading,
  .UIHome .container.whatwedo .heading,
  .UIHome .container.ngenstar .heading {
    width: 90%;
  }
  .UIHome .container.whoweare .heading h3,
  .UIHome .container.whatwedo .heading h3,
  .UIHome .container.ngenstar .heading h3 {
    font-size: 35px;
  }
  .UIHome .container.whoweare .heading h4,
  .UIHome .container.whatwedo .heading h4,
  .UIHome .container.ngenstar .heading h4 {
    font-size: 15px;
  }
  .UIHome .container.cards .heading {
    width: 100%;
  }
  .UIHome .container.cards .heading h3 {
    font-size: 35px;
  }
  .UIHome .container.cards .heading h4 {
    font-size: 15px;
  }
  .UIHome .bigwhoweare .container.whoweare {
    padding: 0;
  }
  .UIHome .cards {
    margin: 80px auto 40px;
  }
  .UIHome .cards .card-shadow img {
    width: 100%;
  }
  .UIHome .bigwhoweare {
    height: auto;
  }
  .UIHome img.whoweare {
    width: 100%;
    margin-top: 10%;
  }
  .UIHome img.whatwedo, .UIHome img.ngenstar {
    width: 100%;
    margin-top: 5%;
  }
  .UIHome .bigwhatwedo, UIHome .bigNgenStar {
    height: auto;
  }
  .UIHome .row.cards .card-text h3 {
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .UIHome .container-fluid.header video {
    width: 100%;
    position: relative;
    left: 0;
    margin-top: 0;
    height: 100%;
  }
  .UIHome .heading {
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .UIHome .heading h1 {
    font-size: 45px;
  }
  .UIHome .heading h2 {
    font-size: 16px;
  }
  .UIHome .container.whoweare .heading,
  .UIHome .container.whatwedo .heading,
  .UIHome .container.ngenstar .heading {
    width: 90%;
  }
  .UIHome .container.whoweare .heading h3,
  .UIHome .container.whatwedo .heading h3,
  .UIHome .container.ngenstar .heading h3 {
    font-size: 45px;
  }
  .UIHome .container.whoweare .heading h4,
  .UIHome .container.whatwedo .heading h4,
  .UIHome .container.ngenstar .heading h4 {
    font-size: 15px;
  }
  .UIHome .container.cards .heading {
    width: 100%;
  }
  .UIHome .container.cards .heading h3 {
    font-size: 45px;
  }
  .UIHome .container.cards .heading h4 {
    font-size: 15px;
  }
  .UIHome .row.cards .card-text h4 {
    font-size: 14px;
  }
  .UIHome .row.cards .card-text h3 {
    font-size: 35px;
  }
  .UIHome .bigwhoweare .container.whoweare {
    padding: 0;
  }
  .UIHome .cards {
    margin: 80px auto 40px;
  }
  .UIHome .cards .card-shadow img {
    width: 100%;
  }
  .UIHome .bigwhoweare {
    height: auto;
  }
  .UIHome img.whoweare {
    width: 100%;
    margin-top: 12%;
  }
  .UIHome img.whatwedo, .UIHome img.ngenstar {
    width: 100%;
    margin-top: 5%;
  }
  .UIHome .bigwhatwedo, UIHome .bigNgenStar {
    height: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .UIHome .container-fluid.header video {
    width: 100%;
    position: relative;
    left: 0;
    margin-top: 0;
    height: 100%;
  }
  .UIHome .heading {
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
  .UIHome .heading h1 {
    font-size: 55px;
  }
  .UIHome .heading h2 {
    font-size: 16px;
  }
  .UIHome .container.whoweare .heading,
  .UIHome .container.whatwedo .heading,
  .UIHome .container.ngenstar .heading {
    width: 90%;
  }
  .UIHome .container.whoweare .heading h3,
  .UIHome .container.whatwedo .heading h3,
  .UIHome .container.ngenstar .heading h3 {
    font-size: 55px;
  }
  .UIHome .container.whoweare .heading h4,
  .UIHome .container.whatwedo .heading h4,
  .UIHome .container.ngenstar .heading h4 {
    font-size: 16px;
  }
  .UIHome .container.cards .heading {
    width: 100%;
  }
  .UIHome .container.cards .heading h3 {
    font-size: 55px;
  }
  .UIHome .container.cards .heading h4 {
    font-size: 16px;
  }
  .UIHome .row.cards .card-text h4 {
    font-size: 12px;
  }
  .UIHome .row.cards .card-text h3 {
    font-size: 25px;
  }
  .UIHome .bigwhoweare .container.whoweare {
    padding: 0;
  }
  .UIHome .cards {
    margin: 80px auto 40px;
  }
  .UIHome .cards .card-shadow img {
    width: 100%;
  }
  .UIHome .bigwhoweare {
    height: auto;
  }
  .UIHome img.whoweare {
    width: 100%;
    margin-top: 0%;
  }
  .UIHome img.whatwedo, .UIHome img.ngenstar {
    width: 100%;
    margin-top: 0;
  }
  .UIHome .bigwhatwedo, UIHome .bigNgenStar {
    height: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .UIHome .container-fluid.header video {
    width: 100%;
    position: relative;
    left: 0;
    margin-top: 0;
    height: 100%;
  }
  .UIHome .heading {
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
  .UIHome .heading h1 {
    font-size: 70px;
  }
  .UIHome .heading h2 {
    font-size: 18px;
  }
  .UIHome .container.whoweare .heading,
  .UIHome .container.whatwedo .heading,
  .UIHome .container.ngenstar .heading {
    width: 90%;
  }
  .UIHome .container.whoweare .heading h3,
  .UIHome .container.whatwedo .heading h3,
  .UIHome .container.ngenstar .heading h3 {
    font-size: 70px;
  }
  .UIHome .container.whoweare .heading h4,
  .UIHome .container.whatwedo .heading h4,
  .UIHome .container.ngenstar .heading h4 {
    font-size: 18px;
  }
  .UIHome .container.cards .heading {
    width: 100%;
  }
  .UIHome .container.cards .heading h3 {
    font-size: 70px;
  }
  .UIHome .container.cards .heading h4 {
    font-size: 18px;
  }
  .UIHome .row.cards .card-text h4 {
    font-size: 15px;
  }
  .UIHome .row.cards .card-text h3 {
    font-size: 35px;
  }
  .UIHome .bigwhoweare .container.whoweare {
    padding: 0;
  }
  .UIHome .cards {
    margin: 80px auto 40px;
  }
  .UIHome .cards .card-shadow img {
    width: 100%;
  }
  .UIHome .bigwhoweare {
    height: auto;
  }
  .UIHome img.whoweare {
    width: 100%;
    margin-top: 0;
  }
  .UIHome img.whatwedo, .UIHome img.ngenstar {
    width: 100%;
    margin-top: 0;
  }
  .UIHome .bigwhatwedo, UIHome .bigNgenStar {
    height: auto;
  }
}