nav.navbar {
    height: 140px;

}

.hidden {
    visibility: hidden;
}
.visible {
    visibility: visible;
}

video.bgvideo.showVideo.blurred {
    filter: blur(30px);
}

li.nav-item p,
li.nav-item a,
li.nav-item span {
    font-family: 'all-round-gothic', sans-serif;
    font-weight: 500;
}

.link.additional {
    font-family: 'all-round-gothic', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    color: #7e8a8a;
    line-height: 40px;
    &:hover {
        color: #5eba47;
    }
}

.additional-links {
    background-color: #5eba47;
    .link.additional.ongreen {
        color: white;
        padding: 5px 20px;
    }
}

nav.navbar .row {
    width: 100%;

    .col:nth-child(1) {
        min-width: 85%;
    }
    .col:nth-child(2) {
        text-align: end;
        z-index: 910;
    }

    .col {
        z-index: 99;
        margin: auto;
    }
}

.navbar-logo.hidden {
    display: none;
}

nav.navbar .menutoogle,
nav.navbar .langMenuToogle {
    height: 50px;
    width: 50px;
    background: none;
    border: none;

    &.langMenuToogleHide {
        >img {
            visibility: hidden;
        }

    }
}

.menu {
    z-index: 99;
}

.star-btn {
    margin: 0 150px 0 0;
    border-radius: 10px 10px 30px 10px;
    padding: 15px 50px 15px 30px;

    img {
        position: absolute;
        margin: -25px -6px;
        transition-duration: .3s;
    }

    &:hover {
        border-radius: 10px 10px 30px 10px;
        padding: 15px 50px 15px 30px;

        img {
            transform: scale(1.1);
        }
    }
}

.starleft-btn {
    margin: 0 150px 0 0;
    border-radius: 10px 10px 30px 10px;
    padding: 15px 50px 15px 30px;
    position: relative;

    img {
        position: absolute;
        margin: -25px -6px;
        transition-duration: .3s;
    }

    &:hover {
        border-radius: 10px 10px 30px 10px;
        padding: 15px 50px 15px 30px;

        img {
            transform: scale(1.1);
        }
    }
}

.menu ul.nav-items {
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    display: -webkit-flex;
    opacity: 1;
    position: fixed;
    flex-direction: column;
    justify-content: center;
}


.menu.showMenu ul.nav-items {
    width: 100%;
    left: 15%;
}

.menu.hideMenu ul.nav-items {
    width: 100%;
    left: 15%;
}

.menu.showMenu,
.menu.hideMenu {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #030308;

    video {
        background-color: #030308;
        width: 100%;
        height: 100vh;
    }
}

li.nav-item {
    list-style: none;
    line-height: 200%;
    font-size: 35px;
    font-weight: 600;
}

li.nav-item:hover {
    list-style: disc;
    color: #5eba47;

    a.link.active {
        list-style: disc;
        color: #5eba47;
    }
}

li.nav-item:has(.active) {
    list-style-type: disc;
    color: #5eba47;
}

li.nav-item.lang:has(.active) {
    list-style-type: none;
    color: #5eba47;
}

a.link.active {
    list-style: disc;
    color: #5eba47;
}


li.nav-item a {
    text-decoration: none;
    color: #7e8a8a;
    text-transform: uppercase;
}

li.nav-item a:hover {
    color: #5eba47;
}

button.langselector {
    background: none;
    color: #7f8a8a;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    border: none;
}

button.langselector:hover {
    color: #5eba47;
}

button.langselector.active a {
    color: #5eba47;
}

.nav-item.lang:hover {
    list-style: none;
}

.nav-item.lang span {
    font-weight: 500;
    color: #7e8a8a;
    font-size: 28px;
}

.subMenu.hideSubMenu {
    position: absolute;
    margin: -13% 18%;
    opacity: 0;
    transition: 0.5s opacity ease-in-out 0s;
    visibility: collapse;
}

.subMenu.showSubMenu {
    position: absolute;
    margin: -13% 18%;
    opacity: 1;
    transition: 0.5s opacity ease-in-out 0s;
}

a i.fa.fa-chevron-right.move {
    margin-left: 40px;
    transition: 0.5s margin-left ease-in-out 0s;
}

a i.fa.fa-chevron-right {
    margin-left: 0;
    transition: 0.5s margin-left ease-in-out 0s;
}

.langMenu.showLangMenu,
.langMenu.hideLangMenu {
    display: block;
    height: 100%;
    width: 100%;
    max-width: 350px;
    background: #030308;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    padding: 60px 30px 30px 30px;

    *,
    p,
    span {
        color: white;
    }

    a {
        text-decoration: none;
        color: #5eba47;

        &:hover {
            text-decoration: underline;
        }
    }

    .row {
        border-bottom: 1px solid #1d1d1d;
        padding: 15px 0;

        .col-md-6 {
            width: auto;
        }
    }

    .country_name {
        font-size: 150%;
    }

    .langMenuClose {
        position: absolute;
        top: 30px;
        right: 30px;
        background: transparent;
        border: none;
        padding: 0;
    }
}


@media (max-width: 575.98px) {
    nav.navbar .row {
        width: -webkit-fill-available;
    }

    .menu.showMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    .menu.hideMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    li.nav-item {
        font-size: 25px;
    }

    .subMenu.hideSubMenu {
        position: absolute;
        margin: 0% 0%;
        opacity: 0;
        transition: 0.5s opacity ease-in-out 0s;
        visibility: collapse;
    }

    .subMenu.showSubMenu {
        position: inherit;
        margin: 0% 0%;
        opacity: 1;
        transition: 0.5s opacity ease-in-out 0s;
    }

    a i.fa.fa-chevron-right.move {
        margin-left: 0px;
        transform: rotate(90deg);
        transition: 0.5s margin-left ease-in-out 0s;
    }

    .menu.showMenu video {
        background-color: #030308;
        width: 80vw;
        height: 100vh;
        object-position: top;
        padding-top: 70px;
    }

    .menu.hideMenu video {
        background-color: #030308;
        width: 80vw;
        height: 100vh;
        object-position: top;
        padding-top: 70px;
    }

}

@media (min-width : 576px) and (max-width: 767.98px) {
    nav.navbar .row {
        width: -webkit-fill-available;
    }

    .menu.showMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    .menu.hideMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    li.nav-item {
        font-size: 25px;
    }

    .subMenu.hideSubMenu {
        position: absolute;
        margin: 0% 0%;
        opacity: 0;
        transition: 0.5s opacity ease-in-out 0s;
        visibility: collapse;
    }

    .subMenu.showSubMenu {
        position: inherit;
        margin: 0% 0%;
        opacity: 1;
        transition: 0.5s opacity ease-in-out 0s;
    }

    a i.fa.fa-chevron-right.move {
        margin-left: 0px;
        transform: rotate(90deg);
        transition: 0.5s margin-left ease-in-out 0s;
    }
}

@media (min-width : 768px) and (max-width: 991.98px) {
    nav.navbar .row {
        width: -webkit-fill-available;
    }

    .menu.showMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    .menu.hideMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    li.nav-item {
        font-size: 30px;
    }

    .subMenu.hideSubMenu {
        position: absolute;
        margin: 0% 0%;
        opacity: 0;
        transition: 0.5s opacity ease-in-out 0s;
        visibility: collapse;
    }

    .subMenu.showSubMenu {
        position: inherit;
        margin: 0% 0%;
        opacity: 1;
        transition: 0.5s opacity ease-in-out 0s;
    }

    a i.fa.fa-chevron-right.move {
        margin-left: 0px;
        transform: rotate(90deg);
        transition: 0.5s margin-left ease-in-out 0s;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    nav.navbar .row {
        width: -webkit-fill-available;
    }

    .menu.showMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    .menu.hideMenu ul.nav-items {
        width: 100vw;
        height: 100vh;
    }

    li.nav-item {
        font-size: 35px;
    }

    .subMenu.showSubMenu {
        margin: -26% 25%;
    }

    .subMenu.hideSubMenu {
        margin: -26% 25%;
    }
}

@media (max-width: 992px) {
    .menu-toggle-wrapper {
        position: relative;

        .star-btn {
            border-radius: 10px;
            position: absolute;
            top: 60px;
            right: 0;
            margin-right: 15px;
            padding: 15px;

            img {
                display: none;
            }
        }
    }

}

@media (max-width: 1207px) {
    .navbar .container .row .col.d-flex.flex-row {
        justify-content: start!important;
    }
    .navbar .container .row .col:nth-child(1) {
        min-width: 70%;
    }
}
